/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";




::-webkit-scrollbar {
  display: none;
}

* {
  font-family: PT_Sans;
}

@font-face {
  font-family: PT_Sans;
  src: url('/assets/fonts/PT_Sans-Narrow-Web-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

.desc-btn {
  color: #11c1f3;
  //width: 32px;
  height: 32px;
  border-radius: 10px;
  padding: 5px;
  font-size: 17px;
  box-shadow: 3px 3px 8px #61616175, -5px -5px 10px #03a9cd;
}

.sc-ion-modal-md-h {
  --width: 100%;
  --min-width: auto;
  --max-width: auto;
  --height: 100%;
  --min-height: auto;
  --max-height: auto;
  --overflow: hidden;
  --border-radius: 0;
  --border-width: 0;
  --border-style: none;
  --border-color: transparent;
  --background: var(--ion-background-color, #fff0);
  --box-shadow: none;
  --backdrop-opacity: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  outline: none;
  contain: strict;
}

@font-face {
  font-family: PT_Sans;
  src: url('../src/assets/fonts/PT_Sans-Narrow-Web-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}



.hrtyle{
  background-color: #dedcdc;
}

.hrtyle1{
  background-color: #0000001f;
}

.active{
  //background-color: rgba(17, 193, 243, 0.91);
  background-color: rgba(17, 193, 243, 0.91);
  color: #ffffff;
  width: 100%;
}

.CardClass{
  padding: 10px;
  font-size: 14px;
  color: #1e1e1e;
  background-color: antiquewhite;
}

.RowClass{
  padding: 10px;
}


.alert-checkbox-label.sc-ion-alert-md {
  padding-left: unset;
  padding-right: unset;
  -webkit-padding-start: -1px;
  padding-inline-start: 29px;
  -webkit-padding-end: 1px;
  padding-inline-end: 1px;
}

.alert-checkbox-icon.sc-ion-alert-md {
  left: 12px;
  top: 0;
  border-radius: 2px;
  position: relative;
  width: 16px;
  height: 16px;
  border-width: 2px;
  border-style: solid;
  border-color: var(--ion-color-step-550, #737373);
  contain: strict;
}

.sc-ion-alert-md-h {
  --min-width: 333px;
  --width: auto;
  --min-height: auto;
  --height: auto;
  --max-height: 90%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  outline: none;
  font-family: var(--ion-font-family, inherit);
  contain: strict;
  -ms-touch-action: none;
  touch-action: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 1001;
}

.alert-radio-label.sc-ion-alert-md {
  padding-left: unset;
  padding-right: unset;
  -webkit-padding-start: 37px;
  padding-inline-start: 37px;
  -webkit-padding-end: 16px;
  padding-inline-end: 26px;
}

.alert-radio-label.sc-ion-alert-md {
  padding-left: 50px;
  padding-right: 21px;
  padding-top: 13px;
  padding-bottom: 13px;
  -ms-flex: 1;
  flex: 1;
  color: var(--ion-color-step-850, #262626);
  font-size: 14px;
  text-overflow: ellipsis;
  /*white-space: nowrap;*/
  overflow: hidden;
  white-space: pre-line !important;
}

/*.my-custom-alert .alert-radio-label{
  font-size: 16px !important;
  white-space: pre-line;
}

.my-custom-alert .alert-wrapper {
  height: 50% !important;
}

.my-custom-alert .alert-radio-group {
  height: 100% !important;
}

.my-custom-alert .alert-tappable{
  height:120px;
}*/

/*.my-custom-alert .alert-radio-button {
  margin-top: 12% !important;
}*/

/*.my-custom-alert .alert-radio-label{
  font-size: 16px !important;
}*/

//col 20

[col-1] {
  padding: 0px;
  margin: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 5%;
  -ms-flex: 0 0 5%;
  flex: 0 0 5%;
  width: 5%;
}

[col-2] {
  padding: 0px;
  margin: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 10%;
  -ms-flex: 0 0 10%;
  flex: 0 0 10%;
  width: 10%;
}

[col-3] {
  padding: 0px;
  margin: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 15%;
  -ms-flex: 0 0 15%;
  flex: 0 0 15%;
  width: 15%;
}

[col-4] {
  padding: 0px;
  margin: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 20%;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  width: 20%;
}
[col-5] {
  padding: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 25%;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  width: 25%;
}
[col-6] {
  padding: 0px;
  margin: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 30%;
  -ms-flex: 0 0 30%;
  flex: 0 0 30%;
  width: 30%;
}
[col-7] {
  padding: 0px;
  margin: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 35%;
  -ms-flex: 0 0 35%;
  flex: 0 0 35%;
  width: 35%;
}
[col-8] {
  padding: 0px;
  margin: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 40%;
  -ms-flex: 0 0 40%;
  flex: 0 0 40%;
  width: 40%;
}
[col-9] {
  padding: 0px;
  margin: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 45%;
  -ms-flex: 0 0 45%;
  flex: 0 0 45%;
  width: 45%;
}
[col-10] {
  padding: 0px;
  margin: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 50%;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  width: 50%;
}
[col-11] {
  padding: 0px;
  margin: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 55%;
  -ms-flex: 0 0 55%;
  flex: 0 0 55%;
  width: 55%;
}
[col-12] {
  padding: 0px;
  margin: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 60%;
  -ms-flex: 0 0 60%;
  flex: 0 0 60%;
  width: 60%;
}
[col-13] {
  padding: 0px;
  margin: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 65%;
  -ms-flex: 0 0 65%;
  flex: 0 0 65%;
  width: 65%;
}
[col-14] {
  padding: 0px;
  margin: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 70%;
  -ms-flex: 0 0 70%;
  flex: 0 0 70%;
  width: 70%;
}
[col-15] {
  padding: 0px;
  margin: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 75%;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  width: 75%;
}
[col-16] {
  padding: 0px;
  margin: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 80%;
  -ms-flex: 0 0 80%;
  flex: 0 0 80%;
  width: 80%;
}
[col-17] {
  padding: 0px;
  margin: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 85%;
  -ms-flex: 0 0 85%;
  flex: 0 0 85%;
  width: 85%;
}
[col-18] {
  padding: 0px;
  margin: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 90%;
  -ms-flex: 0 0 90%;
  flex: 0 0 90%;
  width: 90%;
}
[col-19] {
  padding: 0px;
  margin: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 95%;
  -ms-flex: 0 0 95%;
  flex: 0 0 95%;
  width: 95%;
}
[col-20] {
  padding: 0px;
  margin: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  width: 100%;
}

$alert-md-max-width:100%;
$alert-md-content-max-height:400px;


.fab[mini] {
  background: linear-gradient(to top right,#7b0513  0%,		#7b0513 100%);
  -webkit-box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.14), 0 4px 5px rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.14), 0 4px 5px rgba(0, 0, 0, 0.1) !important;
  //margin-right: 0px !important;
}

.fab-md-in-list {
  color: white;
  background: linear-gradient(to top right,#7b0513  0%,		#c24c4b 100%);
  opacity: 0.9;
  border-radius: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  height: 30px !important;

}

.fab-md-dark {
  color: #fff;
  background: -webkit-gradient(linear, left top, right top, from(#10b2e0), color-stop(80%, #10b2e0));
  //background: linear-gradient(to right, #7b0513 0%, #c24c4b 80%);
  opacity: 0.9;
}


.fab ion-icon {

  text-decoration: none;
  font-size: 20px !important;

}

button[ion-fab] {
  overflow: visible;
  position: relative;
  margin-bottom: 5px;
  opacity: 0.9;

  ion-label {
    position: absolute;
    right: 35px;
    color: white;
    background: linear-gradient(to top right, #222 0%, #353535 100%);
    //background: linear-gradient(to top right,#7b0513  0%,		#c24c4b 100%);
    -webkit-box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.14), 0 4px 5px rgba(0, 0, 0, 0.1) !important;
    box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.14), 0 4px 5px rgba(0, 0, 0, 0.1) !important;
    line-height: 24px;
    padding: 4px 8px;
    border-radius: 10px;
    opacity: 0.9;
    font-family: 'PT Sans', sans-serif !important;
  }
  contain: layout;
}


.loader {
  background-color: transparent;
  border: 4px solid #fcfcfc;
  border-radius: 50%;
  border-top: 4px solid #11c1f3;
  border-bottom: 4px solid #11c1f3;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

::-webkit-scrollbar {
  display: none;
}
//
//@font-face {
//  font-family: PT_Sans;
//  src: url('../assets/fonts/PT_Sans-Narrow-Web-Regular.ttf');
//}

//action Sheet
.action-sheet-group {
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  background-color: whitesmoke !important;
}

.bottom-sheet{
  border-top-right-radius: 20px;
  background: transparent !important;

  .ion-logo-whatsapp{
    color: #25D366 !important;
    font-size: 25px;
  }

  .ion-logo-facebook{
    color: #3b5998 !important;
    font-size: 25px;
  }

  .ion-logo-instagram{
    background:-moz-linear-gradient(left, #e72c83 0%, #a742c6 100%);
    background: -webkit-linear-gradient(left, #e72c83 0%,#a742c6 100%);
    background: linear-gradient(to right, #e72c83 0%,#a742c6 100%);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color:transparent;
    font-size: 25px;
  }

  .ion-logo-twitter{
    color: #50abf1 !important;
    font-size: 25px;
  }

  .button-inner{
    font-family: 'PT Sans', sans-serif !important;
    font-size: 18px;
  }


  .locationicon {
    background-color:#cffcfc;
    padding-top:5px !important;
    padding-bottom: 5px !important;
    //color:#4da6ff;
    //margin-top:5px;
    padding-left:10px !important;
    color: #022431;
  }

  .location-text-icon
  {
    background-color: #11c1f345;
    color: #2f2b2c;
    font-family: 'PT Sans', sans-serif !important;
  }


  .animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  .animated.infinite {
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
  }

  .animated.delay-1s {
    -webkit-animation-delay: 300ms;
    animation-duration:300ms;
  }

  .animated.delay-2s {
    -webkit-animation-delay: 450ms;
    animation-duration: 450ms;
  }

  .animated.delay-3s {
    -webkit-animation-delay: 450ms;
    animation-duration: 450ms;
  }

  .animated.delay-4s {
    -webkit-animation-delay:1200ms;
    animation-delay:1200ms;
  }

  .animated.delay-5s {
    -webkit-animation-delay: 1500ms;
    animation-delay: 1500ms;
  }

  //.animated.delay-6s {
  //  -webkit-animation-delay: 6000ms;
  //  animation-delay: 6000ms;
  //}

  .animated.delay-6s {
    -webkit-animation-delay: 1800ms;
    animation-delay: 1800ms;
  }

  .animated.delay-7s {
    -webkit-animation-delay: 2100ms;
    animation-delay: 2100ms;
  }

  .animated.delay-8s {
    -webkit-animation-delay: 2400ms;
    animation-delay: 2400ms;
  }

  .animated.delay-9s {
    -webkit-animation-delay:2700ms;
    animation-delay: 2700ms;
  }

  .animated.delay-10s {
    -webkit-animation-delay:30000ms;
    animation-delay:30000ms;
  }

  .animated.delay-11s {
    -webkit-animation-delay: 1100ms;
    animation-delay:11000ms;
  }

  .animated.delay-12s {
    -webkit-animation-delay: 1200ms;
    animation-delay: 12000ms;
  }

  .animated.delay-13s {
    -webkit-animation-delay: 13000ms;
    animation-delay:13000ms;
  }

  .animated.delay-14s {
    -webkit-animation-delay:14000ms;
    animation-delay: 14000ms;
  }

  .animated.delay-15s {
    -webkit-animation-delay: 15000ms;
    animation-delay:15000ms;
  }

  .animated.delay-16s {
    -webkit-animation-delay: 16000ms;
    animation-delay:16000ms;
  }

  .animated.delay-17s {
    -webkit-animation-delay: 17000ms;
    animation-delay: 17000ms;
  }

  .animated.delay-18s {
    -webkit-animation-delay:18000ms;
    animation-delay: 18000ms;
  }

  .animated.delay-19s {
    -webkit-animation-delay: 18000ms;
    animation-delay: 18000ms;
  }

  .animated.delay-20s {
    -webkit-animation-delay: 19000ms;
    animation-delay: 19000ms;
  }

  .animated.delay-21s {
    -webkit-animation-delay: 20000ms;
    animation-delay: 20000ms;
  }

  .animated.delay-22s {
    -webkit-animation-delay: 25000ms;
    animation-delay: 25000ms;
  }

  .animated.delay-23s {
    -webkit-animation-delay: 30000ms;
    animation-delay: 30000ms;
  }

  .animated.delay-24s {
    -webkit-animation-delay: 35000ms;
    animation-delay: 35000ms;
  }

  .animated.delay-25s {
    -webkit-animation-delay: 40000ms;
    animation-delay: 40000ms;
  }

  .animated.delay-26s {
    -webkit-animation-delay: 45000ms;
    animation-delay: 45000ms;
  }

  .animated.delay-27s {
    -webkit-animation-delay: 50000ms;
    animation-delay: 50000ms;
  }

  .animated.delay-28s {
    -webkit-animation-delay: 55000ms;
    animation-delay: 55000ms;
  }

  .animated.delay-29s {
    -webkit-animation-delay: 60000ms;
    animation-delay: 60000ms;
  }

  .animated.delay-30s {
    -webkit-animation-delay: 65000ms;
    animation-delay: 65000ms;
  }

  .animated.delay-31s {
    -webkit-animation-delay: 70000ms;
    animation-delay: 70000ms;
  }

  .animated.delay-32s {
    -webkit-animation-delay: 75000ms;
    animation-delay: 75000ms;
  }

  .animated.delay-33s {
    -webkit-animation-delay: 80000ms;
    animation-delay: 80000ms;
  }

  .animated.delay-34s {
    -webkit-animation-delay: 85000ms;
    animation-delay: 85000ms;
  }

  .animated.delay-35s {
    -webkit-animation-delay: 90000ms;
    animation-delay: 90000ms;
  }

  .animated.delay-35s {
    -webkit-animation-delay:95000ms;
    animation-delay: 95000ms;
  }

  .animated.delay-36s {
    -webkit-animation-delay:100000ms;
    animation-delay: 100000ms;
  }

  .animated.delay-37s {
    -webkit-animation-delay: 105000ms;
    animation-delay: 105000ms;
  }

  .animated.delay-38s {
    -webkit-animation-delay: 101000ms;
    animation-delay: 101000ms;
  }

  .animated.delay-39s {
    -webkit-animation-delay: 101500ms;
    animation-delay: 101500ms;
  }

  .animated.delay-40s {
    -webkit-animation-delay: 102000ms;
    animation-delay: 102000ms;
  }

  .animated.delay-41s {
    -webkit-animation-delay: 102500ms;
    animation-delay: 102500ms;
  }

  .animated.delay-42s {
    -webkit-animation-delay: 103000ms;
    animation-delay: 103000ms;
  }

  .animated.delay-43s {
    -webkit-animation-delay: 104000ms;
    animation-delay: 104000ms;
  }

  .animated.fast {
    -webkit-animation-duration: 800ms;
    animation-duration: 800ms;
  }

  .animated.faster {
    -webkit-animation-duration: 500ms;
    animation-duration: 500ms;
  }

  .animated.slow {
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
  }

  .animated.slower {
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
  }

  @-webkit-keyframes flip {
    from {
      -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0)
      rotate3d(0, 1, 0, -360deg);
      transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, -360deg);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
      -webkit-animation-delay: 10000ms;
      animation-delay: 10000ms;
    }
    40% {
      -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px)
      rotate3d(0, 1, 0, -190deg);
      transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px)
      rotate3d(0, 1, 0, -190deg);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }
    50% {
      -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px)
      rotate3d(0, 1, 0, -170deg);
      transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px)
      rotate3d(0, 1, 0, -170deg);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }

    80% {
      -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95) translate3d(0, 0, 0)
      rotate3d(0, 1, 0, 0deg);
      transform: perspective(400px) scale3d(0.95, 0.95, 0.95) translate3d(0, 0, 0)
      rotate3d(0, 1, 0, 0deg);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }

    to {
      -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0)
      rotate3d(0, 1, 0, 0deg);
      transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
  }

  @keyframes flip {
    from {
      -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0)
      rotate3d(0, 1, 0, -360deg);
      transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, -360deg);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }

    40% {
      -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px)
      rotate3d(0, 1, 0, -190deg);
      transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px)
      rotate3d(0, 1, 0, -190deg);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }

    50% {
      -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px)
      rotate3d(0, 1, 0, -170deg);
      transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px)
      rotate3d(0, 1, 0, -170deg);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }

    80% {
      -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95) translate3d(0, 0, 0)
      rotate3d(0, 1, 0, 0deg);
      transform: perspective(400px) scale3d(0.95, 0.95, 0.95) translate3d(0, 0, 0)
      rotate3d(0, 1, 0, 0deg);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }

    to {
      -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0)
      rotate3d(0, 1, 0, 0deg);
      transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
  }

  .animated.flip {
    -webkit-backface-visibility: visible;
    backface-visibility: visible;
    -webkit-animation-name: flip;
    animation-name: flip;
  }

  @-webkit-keyframes flipInX {
    from {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
      transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
      opacity: 0;
    }

    40% {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
      transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }

    60% {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
      transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
      opacity: 1;
    }

    80% {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
      transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    }

    to {
      -webkit-transform: perspective(400px);
      transform: perspective(400px);
    }
  }

  @media (prefers-reduced-motion) {
    .animated {
      -webkit-animation: unset !important;
      animation: unset !important;
      -webkit-transition: none !important;
      transition: none !important;
    }
  }

}

ion-select.complaint-student::part(text) {
  white-space: normal !important;
  transform: none !important;
}

.CustomeModel{
  .alert-wrapper.sc-ion-alert-md {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: var(--width);
    min-width: var(--min-width);
    max-width: var(--max-width);
    height: 3000px;
    min-height: var(--min-height);
    max-height: var(--max-height);
    background: var(--background);
    contain: content;
    opacity: 0;
    z-index: 10;
  }
  .alert-wrapper {
    .alert-button-group {
      justify-content: center;
      position: absolute;
      bottom: 10px;
      .alert-button:first-child {
        color: white;
        background-color: red;
        width:40%;
      }
      .alert-button:nth-child(2) {
        color: white;
        background-color: green;
        width:40%;
      }

    }
  }
}
//.alert-ios .alert-radio-label{ white-space: pre-line;}
//.alert-md .alert-radio-label{ white-space: pre-line;}
//.alert-wp .alert-radio-label{ white-space: pre-line;}
